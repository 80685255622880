import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/website/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "development-process"
    }}>{`Development Process`}</h1>
    <p>{`This is the document I believe more teams need to write. How to develop a 'trivial' CRUD screen, in this case its the tasks part of our task management app.`}</p>
    <p>{`This is a demonstration of the process documentation I describe `}<a parentName="p" {...{
        "href": "./DevelopingProcessDocumentation.md"
      }}>{`here`}</a>{`.`}</p>
    <p>{`In this document we will be building the entire tasks domain of our app, from database to Java API to React UI to Pull Request and finally Deployment.`}</p>
    <p>{`I've focused on getting the steps right for now but will be expanding on what we are actually doing in each step in the future.`}</p>
    <h2 {...{
      "id": "prerequisites"
    }}>{`Prerequisites`}</h2>
    <p><a parentName="p" {...{
        "href": "./SetupDevelopmentTools.md"
      }}>{`Setup Development Tools`}</a>{`.`}</p>
    <h2 {...{
      "id": "design"
    }}>{`Design`}</h2>
    <p>{`Process determines design because it will create a lot of the constraints the design needs to address. I'll work on this in the future when our process has stabilised.`}</p>
    <h2 {...{
      "id": "development"
    }}>{`Development`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Checkout our specially made branch without any tasks.`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`git checkout no-tasks-do-not-merge
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Open glue-stack in VSCode.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Open the terminal using (Control + \`).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Follow the steps at `}<a parentName="p" {...{
            "href": "./RunningLocally.md"
          }}>{`Running locally`}</a>{`, using the plus button in VSCode's terminal to create a new tab.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Take a look at the app without any tasks.`}</p>
      </li>
    </ol>
    <h3 {...{
      "id": "database"
    }}>{`Database`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Open Glue Stack in VSCode.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Start the database by opening a terminal (Control + \`) and running:`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`docker-compose up
`}</code></pre>
      </li>
    </ol>
    <h4 {...{
      "id": "task-table"
    }}>{`Task Table`}</h4>
    <p>{`The task table has a Many-To-One relationship to an organisation `}{`(`}{`just like the user table`}{`)`}{` and a Many-To-One relationship to a user. That means a task can be associated with a user and a user can have tasks associated with them.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Open sequel pro.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Connect to the docker database.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click the plus on the bottom left. Name the table `}<inlineCode parentName="p">{`task`}</inlineCode>{` with utf8mb4 encoding.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add the organisation field.`}</p>
        <p parentName="li">{`The type of this column is going to match the type of the id of the organisation table. To keep things consistent all id columns are the same type.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-text"
          }}>{` Field: organisationId
 Unsigned: true
 Type: INT
 Length: 11
 Allow Null: false
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add a `}<strong parentName="p">{`foreign key`}</strong>{`.`}</p>
        <p parentName="li">{`A foreign key is a relational database constraint that means a particular field must match another field. In this case we're making sure the organisationId references an existing organisation.`}</p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`Click the Relations tab.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Click the plus icon.`}</p>
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-text"
              }}>{` Name: (leave blank)
 Column: organisationId
 References
 Table: organisation
 Column: id
`}</code></pre>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Click Add.`}</p>
          </li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click on the Structure tab again.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click the plus icon in the middle of the page underneath the lone id Field to add a new field.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-text"
          }}>{` Field: name
 Type: VARCHAR
 Length: 255
 Allow Null: false
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add another Field.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-text"
          }}>{` Field: notes
 Type: VARCHAR
 Length: 255
 Allow Null: true
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add another Field.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-text"
          }}>{` Field: userId
 Unsigned: true
 Type: INT
 Length: 11
 Allow Null: true
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add a foreign key constraint to the user table for the previous field.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add another Field.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-text"
          }}>{` Field: statusId
 Unsigned: true
 Type: INT
 Length: 11
 Allow Null: false
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add the standard acive, createdDate, modifiedDate fields by running the following queries in the query tab.`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`ALTER TABLE \`task\` ADD \`active\` BIT(1)  NOT NULL;
ALTER TABLE \`task\` ADD \`createdDate\` DATETIME  NOT NULL;
ALTER TABLE \`task\` ADD \`modifiedDate\` DATETIME  NOT NULL;
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`We've just created our new table so all we need to do now is add it to the codebase. If we added a few fields we could use the statement console (top right) and copy the relevant statements but this time we created a whole table so we can right click the table and select `}<inlineCode parentName="p">{`Copy Create Table Syntax`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Go back to VSCode.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create a `}<inlineCode parentName="p">{`V2__task.sql`}</inlineCode>{` at \``}<inlineCode parentName="p">{`api/src/main/resources/db/migration`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Paste your Create Table Syntax into the file. It should look like this:`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`CREATE TABLE \`task\` (
  \`id\` int(11) unsigned NOT NULL AUTO_INCREMENT,
  \`organisationId\` int(11) unsigned NOT NULL,
  \`name\` varchar(255) NOT NULL DEFAULT '',
  \`notes\` varchar(255) DEFAULT NULL,
  \`userId\` int(11) unsigned DEFAULT NULL,
  \`statusId\` int(11) unsigned DEFAULT NULL,
  \`active\` bit(1) NOT NULL,
  \`createdDate\` datetime NOT NULL,
  \`modifiedDate\` datetime DEFAULT NULL,
  PRIMARY KEY (\`id\`),
  KEY \`organisationId\` (\`organisationId\`),
  KEY \`userId\` (\`userId\`),
  CONSTRAINT \`task_ibfk_1\` FOREIGN KEY (\`organisationId\`) REFERENCES \`organisation\` (\`id\`),
  CONSTRAINT \`task_ibfk_2\` FOREIGN KEY (\`userId\`) REFERENCES \`user\` (\`id\`)
) ENGINE=InnoDB AUTO_INCREMENT=2 DEFAULT CHARSET=utf8mb4;
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Commit your work.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Delete all your tables by selecting them and forcing deletion. This will let Flyway set them up from scratch using the scripts in the migration folder including your new scripts. (TODO: is there a better way?)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Open a new terminal tab.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Run the server.`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`cd api
mvn spring-boot:run
`}</code></pre>
      </li>
    </ol>
    <h3 {...{
      "id": "api"
    }}>{`API`}</h3>
    <h4 {...{
      "id": "entity"
    }}>{`Entity`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Create `}<inlineCode parentName="p">{`Task.java`}</inlineCode>{` at \``}<inlineCode parentName="p">{`api/src/main/java/org/gluestack/api/domain/entity`}</inlineCode>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-text"
          }}>{`package org.gluestack.api.domain.entity;

import javax.persistence.Column;
import javax.persistence.Entity;
import javax.persistence.FetchType;
import javax.persistence.JoinColumn;
import javax.persistence.ManyToOne;
import javax.persistence.Table;

@Entity
@Table(name = "task")
public class Task extends BaseOrganisedEntity {

    @Column(nullable = false, length = 255)
    private String name;

    @Column(length = 255)
    private String notes;

    @ManyToOne(fetch = FetchType.LAZY)
    @JoinColumn(name = "userId")
    private User user;

    @Column(nullable = false)
    private Integer statusId;

    public Task() {
    }
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create the getters and setters.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Stop the API using `}<inlineCode parentName="p">{`Control + c`}</inlineCode>{` in its terminal window.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Start it again (using the up arrow to find the previously run command). This will validate that the entity we created matches the table in the database.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Commit your work.`}</p>
      </li>
    </ol>
    <h4 {...{
      "id": "repository"
    }}>{`Repository`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Create the `}<inlineCode parentName="p">{`TaskRepository.java`}</inlineCode>{` at \``}<inlineCode parentName="p">{`api/src/main/java/org/gluestack/api/repository`}</inlineCode>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-text"
          }}>{` package org.gluestack.api.repository;

 import org.gluestack.api.domain.entity.Task;

 public interface TaskRepository extends BaseRepository<Task> {
 }
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Commit your work.`}</p>
      </li>
    </ol>
    <h4 {...{
      "id": "service"
    }}>{`Service`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Create the `}<inlineCode parentName="p">{`TaskService.java`}</inlineCode>{` at \``}<inlineCode parentName="p">{`api/src/main/java/org/gluestack/api/service`}</inlineCode>{`.`}</p>
        <p parentName="li">{`In this case the TaskService wants to return related entities to the user `}{`(`}{`the user details of the assigned user`}{`)`}{` so we use hibernate to load those details and the `}<inlineCode parentName="p">{`jackson-datatype-hibernate5`}</inlineCode>{` module will automatically serialise it (convert to JSON).`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-text"
          }}>{`package org.gluestack.api.service;

import org.gluestack.api.domain.entity.QTask;
import org.gluestack.api.domain.entity.Task;
import org.gluestack.api.domain.entity.User;
import com.querydsl.core.types.Predicate;
import org.hibernate.Hibernate;
import org.springframework.data.domain.Page;
import org.springframework.data.domain.Pageable;
import org.springframework.stereotype.Service;

@Service
public class TaskService extends BaseService<Task> {

    @Override
    public Predicate getReadPermissionPredicate(User principalUser) {
        return QTask.task.organisation.id.eq(principalUser.getOrganisation().getId());
    }

    @Override
    public Page<Task> findAll(User principalUser, Predicate predicate, Pageable pageRequest) {
        Page<Task> tasks = super.findAll(principalUser, predicate, pageRequest);
        tasks.getContent().forEach(task -> Hibernate.initialize(task.getUser()));
        return tasks;
    }
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Commit your work.`}</p>
      </li>
    </ol>
    <h4 {...{
      "id": "controller"
    }}>{`Controller`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Create the `}<inlineCode parentName="p">{`TaskController.java`}</inlineCode>{` at \``}<inlineCode parentName="p">{`api/src/main/java/org/gluestack/api/controller`}</inlineCode>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-text"
          }}>{`package org.gluestack.api.controller;

import org.gluestack.api.domain.entity.Task;
import org.gluestack.api.domain.entity.User;
import org.gluestack.api.service.TaskService;
import com.querydsl.core.types.Predicate;
import org.springframework.beans.factory.annotation.Autowired;
import org.springframework.data.domain.Page;
import org.springframework.data.domain.Pageable;
import org.springframework.data.querydsl.binding.QuerydslPredicate;
import org.springframework.security.core.Authentication;
import org.springframework.web.bind.annotation.RequestMapping;
import org.springframework.web.bind.annotation.RequestMethod;
import org.springframework.web.bind.annotation.RestController;

@RestController
@RequestMapping("api/tasks")
public class TaskController extends BaseController<Task> {

    @Autowired
    private TaskService taskService;

    @RequestMapping(method = RequestMethod.GET)
    public Page<Task> findAll(Authentication authentication, @QuerydslPredicate Predicate predicate,
            Pageable pageRequest) {
        User principalUser = (User) authentication.getPrincipal();
        return taskService.findAll(principalUser, predicate, pageRequest);
    }

}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Commit your work.`}</p>
      </li>
    </ol>
    <h4 {...{
      "id": "tests"
    }}>{`Tests`}</h4>
    <p>{`Our current test framework means you make it easy to reuse the test data you use in your tests so there is lest time wasted setting up the same data scenarios over and over again.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Open `}<inlineCode parentName="p">{`TestOrganisation.java`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add a Task field called `}<inlineCode parentName="p">{`taskAssignedToActingUser`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Open `}<inlineCode parentName="p">{`TestOrganisationService.java`}</inlineCode>{` and populate that test Task at the end of the create method.`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`Task taskAssignedToActingUser = new Task();
taskAssignedToActingUser.setName("Assigned Task");
taskAssignedToActingUser.setNotes("Assigned To Acting User");
taskAssignedToActingUser.setStatusId(1);
taskAssignedToActingUser.setUser(actingUser);
taskAssignedToActingUser.setOrganisation(organisation);
taskRepository.save(taskAssignedToActingUser);
testOrganisation.taskAssignedToActingUser = taskAssignedToActingUser;
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create `}<inlineCode parentName="p">{`FindAllTest.java`}</inlineCode>{` at `}<inlineCode parentName="p">{`api/src/test/java/org/gluestack/api/task`}</inlineCode>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`package org.gluestack.api.task;

import org.gluestack.api.BaseTest;
import org.gluestack.api.PageResponse;
import org.gluestack.api.domain.entity.Task;
import static org.hamcrest.MatcherAssert.assertThat;
import static org.hamcrest.Matchers.equalTo;
import static org.hamcrest.Matchers.hasSize;
import static org.junit.Assert.assertEquals;
import org.junit.Test;
import static org.springframework.security.test.web.servlet.request.SecurityMockMvcRequestPostProcessors.httpBasic;
import org.springframework.test.web.servlet.MvcResult;
import static org.springframework.test.web.servlet.request.MockMvcRequestBuilders.get;

public class FindAllTest extends BaseTest {

    @Test
    public void findAllUsersTest() throws Exception {
        MvcResult mvcResult = mvc
                .perform(get("/api/tasks").with(httpBasic(testOrganisation.actingUser.getUsername(), "password")))
                .andReturn();
        PageResponse<Task> page = objectMapper.readValue(mvcResult.getResponse().getContentAsString(),
                objectMapper.getTypeFactory().constructParametricType(PageResponse.class, Task.class));

        int resultSize = 1;
        assertEquals(resultSize, page.getNumberOfElements());
        assertEquals(resultSize, page.getTotalElements());
        assertThat(page.getContent(), hasSize(resultSize));

        Task result = page.getContent().get(0);

        assertThat(result.getName(), equalTo(testOrganisation.taskAssignedToActingUser.getName()));
        assertThat(result.getNotes(), equalTo(testOrganisation.taskAssignedToActingUser.getNotes()));
        assertThat(result.getStatusId(), equalTo(testOrganisation.taskAssignedToActingUser.getStatusId()));
        assertThat(result.getUser().getId(), equalTo(testOrganisation.taskAssignedToActingUser.getUser().getId()));
    }
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Run `}<inlineCode parentName="p">{`mvn test`}</inlineCode>{` to make sure its all working!`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Commit your work.`}</p>
      </li>
    </ol>
    <h3 {...{
      "id": "ui"
    }}>{`UI`}</h3>
    <p><a parentName="p" {...{
        "href": "https://news.ycombinator.com/item?id=17569848"
      }}>{`JavaScript fundamentals before learning React`}</a>{` is a good resource to understand Javascript while learning React.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Create a task folder at `}<inlineCode parentName="p">{`ui/src/main/authenticated/task`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create the `}<inlineCode parentName="p">{`index.js`}</inlineCode>{` file inside that folder. This will be the root task page.`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`import React, { Component } from 'react';

class Task extends Component {
    render() {
        return (
            <div>tasks</div>
        );
    }
}

export { Task};
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Save.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Open `}<inlineCode parentName="p">{`authenticated/index.js`}</inlineCode>{` (Using `}<inlineCode parentName="p">{`Command+p`}</inlineCode>{` then typing `}<inlineCode parentName="p">{`auth/ind`}</inlineCode>{`).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add the following below the "users" Route in the render function.`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`<Route
    path="/tasks"
    render={props => (
        <Task
            {...props}
            {...this.props}
            toggleSideBar={this.toggleSideBar}
        />
    )}
/>
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Put your cursor at the end of the word `}<inlineCode parentName="p">{`<Task`}</inlineCode>{`, hit `}<inlineCode parentName="p">{`Control + Space`}</inlineCode>{` and select the Task that says auto import (mine was the second option). It should add the following to the top of the file.`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`import { Task } from "./task";
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Save.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Open `}<inlineCode parentName="p">{`sidebar/index.js`}</inlineCode>{` using the same hotkey and technique as before.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add the following below the "users" `}<inlineCode parentName="p">{`<ListItem`}</inlineCode>{` in the render function.`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`<ListItem component={Link} to="/tasks" button>
    <ListItemText primary="Tasks" />
</ListItem>
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Save`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`You should now be able to click the Tasks item in the side bottom and it should render a page saying "tasks".`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create the `}<inlineCode parentName="p">{`task.js`}</inlineCode>{` file at `}<inlineCode parentName="p">{`ui/src/api/`}</inlineCode>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`import axios from "axios";

const path = "tasks";

export function findAll({ name, userId, statusId, page, size, sort } = {}) {
    return axios.get(path, {
        params: { name, "user.id": userId, statusId, page, size, sort },
    });
}

export function findOne(id) {
    return axios.get(\`\${path}/\${id}\`);
}

export function patch(id, body) {
    return axios.patch(\`\${path}/\${id}\`, body);
}

export function save(body) {
    if (body.id) {
        return patch(body.id, body);
    } else {
        return axios.post(path, body);
    }
}

export const TaskStatus = {
    TODO: 0,
    IN_PROGRESS: 1,
    DONE: 2,
};
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create the list component. Create a new file in the`}<inlineCode parentName="p">{`ui/src/main/authenticated/task/`}</inlineCode>{` folder called `}<inlineCode parentName="p">{`list.js`}</inlineCode>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import Hidden from "@material-ui/core/Hidden";
import { TableCell } from "common/components/TableCell";
import { parseURL } from "common/parseURL";
import { TablePagination } from "common/components/TablePagination";
import { TableSortLabel } from "common/components/TableSortLabel";
import { findAll } from "api/task";

class List extends Component {
    render() {
        const { findAll } = this.props;
        return (
            <div style={{ width: "100%", overflow: "auto" }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel findAll={findAll} property="name">
                                    Name
                                </TableSortLabel>
                            </TableCell>
                            <Hidden smDown>
                                <TableCell>
                                    <TableSortLabel findAll={findAll} property="notes">
                                        Notes
                                    </TableSortLabel>
                                </TableCell>
                            </Hidden>
                            <TableCell>
                                <TableSortLabel findAll={findAll} property="statusId">
                                    Status
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel findAll={findAll} property="user.firstName">
                                    Assigned
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {findAll.rejected && (
                            <TableRow>
                                <TableCell colSpan="3">
                                    {findAll.reason ? (
                                        <div>
                                            <p>{findAll.reason.error}</p>
                                            <p>{findAll.reason.exception}</p>
                                            <p>{findAll.reason.message}</p>
                                        </div>
                                    ) : (
                                        <p>Error</p>
                                    )}
                                </TableCell>
                            </TableRow>
                        )}
                        {findAll.value &&
                            findAll.value.data.content.map(row => <div>{row.id}</div>)}
                    </TableBody>
                    {findAll.fulfilled && (
                        <TableFooter>
                            <TableRow>
                                <TablePagination findAll={findAll} />
                            </TableRow>
                        </TableFooter>
                    )}
                </Table>
            </div>
        );
    }
}

export { List };

export const connectConfig = {
    findAll: {
        params: props => {
            const { search, statusId, userId } = props.params;
            return {
                ...parseURL(props),
                name: search,
                statusId: statusId != null ? Number(statusId) : statusId,
                userId: userId != null ? Number(userId) : userId,
            };
        },
        promise: findAll,
    },
};
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Change the `}<inlineCode parentName="p">{`index.js`}</inlineCode>{` to this:`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`import React, { Component, Fragment } from "react";
import { Route } from "react-router-dom";
import componentQueries from "react-component-queries";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Add from "@material-ui/icons/Add";
import { Container } from "common/components/Container";
import { Page } from "common/components/Page";
import { AppBar } from "common/components/AppBar";
import { MenuButton } from "common/components/MenuButton";
import { AppBarTitle } from "common/components/AppBarTitle";
import { RefreshButton } from "common/components/RefreshButton";
import { Link } from "common/components/Link";
import { connect } from "common/connector";
import { urlStateHolder } from "common/stateHolder";
import { List, connectConfig } from "./list";

const styles = theme => ({
    inputRoot: {
        color: "inherit",
    },
});

class Task extends Component {
    render() {
        const { match, findAll, toggleSideBar, singleView } = this.props;

        return (
            <Container>
                <Route
                    path={\`\${match.path}\`}
                    exact={singleView}
                    render={props => (
                        <Page>
                            <AppBar>
                                <Fragment>
                                    <MenuButton toggleSideBar={toggleSideBar} />
                                    <AppBarTitle>Tasks</AppBarTitle>

                                    <RefreshButton findAll={findAll} />
                                    <IconButton
                                        component={Link}
                                        to={\`\${match.path}/create\`}
                                        color="inherit"
                                    >
                                        <Add />
                                    </IconButton>
                                </Fragment>
                            </AppBar>
                            <List {...props} listURL={match.path} findAll={findAll} />
                        </Page>
                    )}
                />
            </Container>
        );
    }
}

Task = withStyles(styles)(
    componentQueries({
        queries: [
            ({ width }) => ({
                singleView: width < 1000,
            }),
        ],
        config: { pure: false },
    })(urlStateHolder(connect(connectConfig)(Task)))
);

export { Task };
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The tasks page should now be starting to look more like the users page.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Next we will work on Adding a task.`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      